import { Cupo, ExtraData, ExtraDataField, Ubicacion } from "./interfaces";

// CONSTANTES DE ENTORNO
export const LOADED_INTERVAL = 500;

//CAMPOS DE LA TABLA MODELO
export const FIELDS_MODELO = <ExtraData>{
    campos: [
        <ExtraDataField>{
            nombre: "timon",
            descriptor: "Timón",
            mandatory: true,
            tipo: "string",
            orden: 0
        },
        <ExtraDataField>{
            nombre: "eslora",
            descriptor: "Eslora",
            mandatory: true,
            tipo: "number",
            orden: 1
        },
        <ExtraDataField>{
            nombre: "manga",
            descriptor: "Manga",
            mandatory: true,
            tipo: "number",
            orden: 2
        },
        <ExtraDataField>{
            nombre: "calado",
            descriptor: "Calado",
            mandatory: true,
            tipo: "number",
            orden: 3
        },
        <ExtraDataField>{
            nombre: "motor",
            descriptor: "Motor",
            mandatory: true,
            tipo: "string",
            orden: 4
        },
        <ExtraDataField>{
            nombre: "l_agua",
            descriptor: "L. agua",
            mandatory: true,
            tipo: "number",
            orden: 5
        },
        <ExtraDataField>{
            nombre: "l_fuel",
            descriptor: "L. gasolina",
            mandatory: true,
            tipo: "number",
            orden: 6
        }
    ]
}

const VALOR_CUPO_PDEF = 10;
export const CUPO_PDEF = <Cupo> {
    id_cupo: -1,
    bloqueado: false,
    valor_antelacion: 1,
    valor_cupo: VALOR_CUPO_PDEF,
    valor_minimo: 1,
    valor_precio: 1,
    valor_unidad_precio: "DD",
    valor_precio_kids: 1,
    valor_release: 1,
    valor_unidad_antelacion: "DD",
    valor_unidad_release: "DD"
}

export const BOOKING_TYPE = "request";

export const FREE_MODEL_TYPES = false;

export const SYSTEM_STATE_DEBUG = false;

export const SYSTEM_TYPES = true;

export const DEFAULT_LOCATION = <Ubicacion> {
    id_ubicacion: -1,
    lat: 0,
    lon: 0,
    nombre: "",
    zoom: 1,
    marcada: false
}

export const DEV_STAGE = false;

export const SYSTEM_NAME = "yachter";

// STATICALLY DEFINED